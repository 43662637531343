export default {
  data: () => ({
    loading: true,
    createModal: false,
    editModal: false,
    item: {}
  }),
  async mounted () {
    await this.getNews()
  },
  methods: {
    async getNews () {
      const url = '/admin/news'
      await this.$http
        .get(url)
        .then(res => {
          this.items = res.data
        })
        .finally(() => {
          this.loading = false
          this.totalRows = this.items.length
        })
    },
    open (id) {
      this.item = JSON.parse(
        JSON.stringify(this.items.find(item => item.id === id))
      )
      this.editModal = true
    },
    created (payload) {
      // update all position to + 1
      this.items.forEach((item, index) => {
        if (item.pos >= payload.pos) {
          this.items[index].pos++
        }
      })

      this.items.unshift(payload)
    },
    updated (payload) {
      Object.assign(
        this.items.find(item => item.id === payload.id),
        payload
      )
    },
    async toggleHidden (item) {
      this.loading = true
      const url = `/admin/news/set_hidden/${item.id}`

      await this.$http.get(url)
        .finally(() => {
          this.items.find(i => i.id === item.id).hidden = item.hidden === 0 ? 1 : 0
          this.loading = false
        })
    },
    async positionUp (item) {
      this.loading = true
      const url = `/admin/news/position_up/${item.id}`

      if (item.pos === 1) {
        return
      }

      await this.$http.get(url)
        .finally(() => {
          // this.items.find(i => i.id === item.id).pos = item.pos - 1
          // this.items.find(i => i.id === item.id).pos = item.pos

          // up position
          const index = this.items.indexOf(item)
          this.items[index].pos--
          this.items[index - 1].pos++

          this.items.splice(index, 1)
          this.items.splice(index - 1, 0, item)
          this.loading = false
        })
    },
    async positionDown (item) {
      this.loading = true
      const url = `/admin/news/position_down/${item.id}`

      if (item.pos === this.items.length) {
        return
      }

      await this.$http.get(url)
        .finally(() => {
          const index = this.items.indexOf(item)
          this.items[index].pos++
          this.items[index + 1].pos--

          this.items.splice(index, 1)
          this.items.splice(index + 1, 0, item)
          this.loading = false
        })
    },
    getColor (color) {
      let response = {}

      switch (color) {
      case 'primary':
        response = {
          message: 'Azul',
          color: 'relief-primary'
        }
        break

      case 'secondary':
        response = {
          message: 'Cinza',
          color: 'relief-secondary'
        }
        break

      case 'success':
        response = {
          message: 'Verde',
          color: 'relief-success'
        }
        break

      case 'danger':
        response = {
          message: 'Vermelho',
          color: 'relief-danger'
        }
        break

      case 'warning':
        response = {
          message: 'Laranja',
          color: 'relief-warning'
        }
        break

      case 'info':
        response = {
          message: 'Azul Claro',
          color: 'relief-info'
        }
        break

      case 'dark':
        response = {
          message: 'Escuro',
          color: 'relief-dark'
        }
        break

      default:
        response = {
          message: 'Nenhum Serviço',
          color: 'relief-primary'
        }
      }

      return response
    },
    deleteModal (item) {
      this.$swal({
        title: `Você tem certeza que deseja deletar "${item.name}"?`,
        text: 'Essa ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!',
        cancelButtonText: 'Fechar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.delete(item.id)
        }
      })
    },
    async delete (id) {
      this.loading = true
      const url = `/admin/news/${id}`
      await this.$http
        .delete(url)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Deletado!',
            text: 'A Opção selecionada foi deletada com sucesso!',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })

          this.items = this.items.filter(item => item.id !== id)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
