<template>
  <section>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="800px"
      @change="(val) => $emit('update:visible', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Editar Serviço
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="API"
            label-for="service-api-name"
          >
            <b-form-select
              id="service-api-name"
              v-model="item.color"
              :state="item.color === null ? false : true"
              :options="options"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Por favor, selecione uma API!</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Descrição"
            label-for="service-description"
          >
            <quill-editor v-model="item.text" />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Limpar
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    quillEditor
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    options: [
      {
        value: 'primary',
        text: 'Azul'
      },
      {
        value: 'secondary',
        text: 'Cinza'
      },
      {
        value: 'success',
        text: 'Verde'
      },
      {
        value: 'danger',
        text: 'Vermelho'
      },
      {
        value: 'warning',
        text: 'Laranja'
      },
      {
        value: 'info',
        text: 'Azul Claro'
      },
      {
        value: 'dark',
        text: 'Escuro'
      }
    ]
  }),
  methods: {
    clearForm () {
      //
    },
    async handleSubmit () {
      const url = `/admin/news/${this.item.id}`
      const params = {
        ...this.item
      }

      this.$http.patch(url, params).finally(() => {
        this.$emit('updated', this.item)
        this.$emit('update:visible', false)

        this.$swal({
          title: 'Atualizado com sucesso!!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          },
          buttonsStyling: false
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
