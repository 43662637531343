<template>
  <section>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="800px"
      @change="(val) => $emit('update:visible', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Criar Serviço
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="API"
            label-for="color"
          >
            <b-form-select
              id="color"
              v-model="formData.color"
              :state="formData.color === null ? false : true"
              :options="options"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Por favor, selecione uma API!</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Texto"
            label-for="text"
          >
            <quill-editor v-model="formData.text" />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Limpar
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    quillEditor
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    created_item: {},
    formData: {
      text: '',
      color: 'primary'
    },
    options: [
      {
        value: 'primary',
        text: 'Azul'
      },
      {
        value: 'secondary',
        text: 'Cinza'
      },
      {
        value: 'success',
        text: 'Verde'
      },
      {
        value: 'danger',
        text: 'Vermelho'
      },
      {
        value: 'warning',
        text: 'Laranja'
      },
      {
        value: 'info',
        text: 'Azul Claro'
      },
      {
        value: 'dark',
        text: 'Escuro'
      }
    ],
    selected: null
  }),
  methods: {
    clearForm () {
      this.formData = {
        text: '',
        color: 'primary'
      }
    },
    async handleSubmit () {
      const url = '/admin/news'
      const params = {
        ...this.formData
      }

      this.$http
        .post(url, params)
        .then(res => {
          this.created_item = res.data
        })
        .finally(() => {
          this.$emit('updated', this.created_item)
          this.$emit('update:visible', false)

          this.$swal({
            title: 'Criado com sucesso!!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            },
            buttonsStyling: false
          })
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
